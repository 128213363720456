import {
    IonButton,
    IonButtons,
    IonFooter,
    IonIcon,
    IonToolbar
} from '@ionic/react';
import './ExploreContainer.css';
import { call } from 'ionicons/icons';
import React from "react";

interface ContainerProps {
    ws: WebSocket|null,
    phoneNumber: string
}

const ExploreContainer: React.FC<ContainerProps> = ({ ws, phoneNumber }) => {
    return (
        <IonFooter>
            <IonToolbar>
                <IonButtons slot={'primary'} style={{ position: 'absolute', left: '10px' }}>
                    <IonButton onClick={() => ws?.send(JSON.stringify({ channel: 'send_call', data: { phone: phoneNumber } }))}>
                        <IonIcon icon={call} style={{ color: 'green' }} />
                    </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ position: 'absolute', right: '10px' }}>
                    <IonButton onClick={() => ws?.send(JSON.stringify({ channel: 'send_hang_up', data: { phone: phoneNumber } }))}>
                        <IonIcon icon={call} style={{ transform: 'rotate(90deg)', color: 'red' }} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    );
};

export default ExploreContainer;
