import React, {useEffect, useState} from "react";
import {
    IonButton, IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import {checkmark, trash, close} from "ionicons/icons";

const Home: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('0763207630');
  const [connected, setConnected] = useState(false);
  const [_ws, setWs] = useState<WebSocket|null>(null);

  useEffect(() => {
      const wsUrl = 'ws://crm.react.ws.nicolaschoquet.fr/ws';
      // const wsUrl = `ws://${window.location.hostname}:8080/ws`;
      const ws = new WebSocket(wsUrl);

      ws.addEventListener('open', () => {
          setConnected(true);
      });

      ws.addEventListener('error', () => {
          setConnected(false);
      });

      setWs(ws);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Téléphone {connected ? <IonIcon icon={checkmark} style={{ color: 'green' }} /> : <IonIcon icon={close} style={{ color: 'red' }} />}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Téléphone</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ fontSize: '25px', padding: '5px', marginTop: '10px' }}>
                {phoneNumber}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '1')}> 1 </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '2')}> 2 </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '3')}> 3 </IonButton>
                </IonButtons>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '4')}> 4 </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '5')}> 5 </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '6')}> 6 </IonButton>
                </IonButtons>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '7')}> 7 </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '8')}> 8 </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '9')}> 9 </IonButton>
                </IonButtons>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '*')}> * </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber + '0')}> 0 </IonButton>
                </IonButtons>

                <IonButtons slot={'primary'} style={{ padding: '10px', fontSize: '40px' }}>
                    <IonButton onClick={() => setPhoneNumber(phoneNumber.substring(0, phoneNumber.length - 1))}>
                        <IonIcon icon={trash} />
                    </IonButton>
                </IonButtons>
            </div>
        </div>
      </IonContent>

      <ExploreContainer ws={_ws} phoneNumber={phoneNumber} />
    </IonPage>
  );
};

export default Home;
